@import './responsive.css';

/* Custom Variables */
:root {
  --dark-theme-bg: #292929;
  --dark-theme-bg2: #1F1F1D;
  --theme-blue1: #68a0f3;
  --theme-color2: #dc3545;
  --theme-color3: #303030;
  --theme-color4: #969fc2;
  --theme-color5: #717b9e;
  --theme-color6: #76e190;
  --text-secondary: #b2b2b2;
  
  --custom-bg: #454545;
  --topbar-height: 76px;
  --body-height1: calc(100svh - 76px);
  --topbar-mob-height: 60px;
  --body-mob-height1: calc(100svh - 120px);
}
.text-clr3 {
  color: var(--text-secondary);
}

/* .light-mode {
  --dark-theme-bg: #7532f9;
  --dark-theme-bg2: #fff;
} */

/* Global CSS */
html {
  overflow-x: hidden;
}

body {
  background-color: var(--dark-theme-bg2);
  color: var(--text-secondary);
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.min-h-auto {
  min-height: auto !important;
}

.dark-theme-bg {
  background-color: var(--dark-theme-bg)
}

.dark-theme-bg2 {
  background-color: var(--theme-color3)
}

.dark-theme-bg3 {
  background-color: var(--custom-bg)
}

.border-black {
  border-color: #000 !important;
}

.btn-custom {
  min-width: 120px;
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.main-heading {
  font-size: clamp(25px, 3vw, 30px);
  font-weight: bold;
  line-height: normal;
}

.text-clr1 {
  color: var(--theme-color4);
}

.text-clr2 {
  color: var(--theme-color5);
}

.text-red {
  color: red;
}

.text-green {
  color: #03ff85;
}

.text-cstm-green {
  color: var(--theme-color6);
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px;
}

.fw-medium {
  font-weight: 500;
}

.z-3 {
  z-index: 3 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-1 {
  z-index: 1 !important;
}

/* .btn {
  border-radius: var(--bs-border-radius-sm) !important;
} */

.z-3 {
  z-index: 3 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-1 {
  z-index: 1 !important;
}

button.btn {
  border-radius: var(--bs-border-radius-sm);
}

.row-gap-0 {
  row-gap: 0 !important
}

.row-gap-1 {
  row-gap: .25rem !important
}

.row-gap-2 {
  row-gap: .5rem !important
}

.row-gap-3 {
  row-gap: 1rem !important
}

.row-gap-4 {
  row-gap: 1.5rem !important
}

.row-gap-5 {
  row-gap: 3rem !important
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important
}

.column-gap-1 {
  -moz-column-gap: 0.25rem !important;
  column-gap: .25rem !important
}

.column-gap-2 {
  -moz-column-gap: 0.5rem !important;
  column-gap: .5rem !important
}

.column-gap-3 {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important
}

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important
}

.column-gap-5 {
  -moz-column-gap: 3rem !important;
  column-gap: 3rem !important
}

/* Start: Intoduction Page Common CSS */
.introduction_page_container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(./resources/images/Introduction-Page.png) no-repeat;
  background-size: cover;
}

.introduction_page_container .introduction-body {
  min-height: 500px;
  max-height: 500px;
  overflow: auto;
}

.introduction_page_panel {
  max-width: 450px;
  filter: drop-shadow(0 0 0.2rem grey);
}

.introduction_page_panel .logo-img-cover img {
  max-width: 320px;
  width: 100%;
}

.introduction-body .introduction-subtitle {
  color: var(--bs-gray-900);
}

.join_room_button {
  /* background-color: #2d8cff;
  border: 1px solid #e5e5e5;
  color: white; */
  font-size: 13px;
  font-weight: 700;
  width: 180px;
  height: 30px;
  border-radius: 8px;
  transition: 0.3s;
}

.join_room_button:hover {
  background-color: blue;
}

.create_room_button {
  color: #000;
  font-size: 14px;
  padding: 8px 20px;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  transition: 0.3s;
  min-width: 150px;
}

.create_room_button:hover {
  background-color: #e5e5e5;
}

.introduction_page_container .introduction-footer {
  background-color: #d6d6d6;
  padding: 15px;
  color: var(--bs-gray-900);
}

/* Start: Live Listing Page CSS */

/* BDM & CAM list css */
.bdm-cam-wrapper .user-slider-wrapper {
  color: #8c8c8c;
  background-color: var(--theme-color3);
  border: 1px solid transparent;
  cursor: pointer;
}

.bdm-cam-wrapper .user-slider-wrapper:hover {
  border-color: var(--bs-warning);
}

@media (min-width: 992px) and (max-width: 1199px) {
  .bdm-cam-wrapper .btn-cam-wrapper button {
    font-size: .875rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.bdm-cam-wrapper button.active {
  border-color: var(--bs-blue);
  background-color: var(--bs-blue);
  background-color: var(--bs-blue);
}

.bdm-cam-wrapper .user-status {
  bottom: 5px;
  right: 2px;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
}

.bdm-cam-wrapper .slick-list {
  margin: 0 -5px;
}

.bdm-cam-wrapper .slick-slide {
  padding: 0 5px;
}

.bdm-cam-wrapper #cam-btn1 .slick-track {
  margin-left: 0;
}

.bdm-cam-wrapper #bdm-btn1 .slick-track {
  margin-right: 0;
}

.bdm-cam-wrapper .no-bdm .slick-track {
  margin-left: auto !important;
  margin-right: auto !important;
}

.bdm-cam-wrapper .user-slider-wrapper .btn-wrapper {
  height: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.75);
  transition: all .4s;
}

.bdm-cam-wrapper .user-slider-wrapper:hover .btn-wrapper {
  height: 100%;
  opacity: 1;
  visibility: visible;
}

/* Go live model model css */
.join-call-model .user-info {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
}

.join-call-model .video-sec #video-preview {
  height: 160px;
  display: block;
}

.join-call-model .video-sec video,
.join-call-model .video-sec iframe {
  height: 100%;
  width: 100%;
  display: block;
}

/* chart css */
.amchart-wrapper g[shape-rendering="auto"] {
  display: none;
}

.live-listing-wrapper .main-heading span {
  font-size: 1.25rem;
}

.amchart-wrapper .accordion-button:after {
  filter: invert(100%);
}

.amchart-wrapper .accordion-button:not(.collapsed)::after {
  filter: invert(100%);
  background-image: var(--bs-accordion-btn-icon);
}

.amchart-wrapper .chart-heading {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
  /* display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 43px; */
}

.amchart-wrapper .accordion-body {
  /* max-height: 60vh; */
  /* overflow: auto; */
  background: linear-gradient(168.68deg, #ffffff 1.12%, #00a3fd 100%);
}

.amchart-wrapper .graph-cover {
  height: 250px;
  width: 100%;
}

.live-listing-wrapper .table-responsive {
  z-index: 1;
  position: relative;
}

.live-listing-wrapper .table-responsive table {
  color: #8c8c8c;
  font-size: 0.875rem;
}

.live-listing-wrapper .list-item-wrapper {
  width: 100%;
}

.live-listing-wrapper table thead tr {
  background: linear-gradient(168.68deg, #0052c9 1.12%, #0a91db 100%);
  height: 60px;
}

.live-listing-wrapper table thead th {
  color: #fff;
  vertical-align: middle;
  white-space: nowrap;
}

.live-listing-wrapper table tbody tr {
  background-color: var(--theme-color3);
}

.live-listing-wrapper table tbody tr:hover {
  background-color: #3a3a3a;
}

.live-listing-wrapper .list-item {
  text-align: left;
  box-sizing: border-box;
}

.live-listing-wrapper .time-state {
  font-size: 10px;
  font-weight: 500;
  display: flex;
  padding: 3px 0;
  border-bottom: 1px solid #535353;
}

.live-listing-wrapper .time-state span {
  color: var(--bs-white);
  opacity: 0.6;
}

.live-listing-wrapper .client-name {
  font-size: 1rem;
  font-weight: 500;
  color: var(--theme-blue1);
  word-wrap: break-word;
}

.live-listing-wrapper .country-name {
  font-size: 1rem;
}

.live-listing-wrapper .total-participants span,
.live-listing-wrapper .mob-number span {
  width: 40px;
}

.live-listing-wrapper .total-participants i {
  color: var(--theme-color2);
  font-size: 25px;
}

.live-listing-wrapper .total-participants.active i {
  color: #198754;
}

.live-listing-wrapper .live-btn {
  background-color: var(--theme-color2);
  ;
  color: #fff;
  padding: 1px 3px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  width: auto !important;
}

/* Start: Topbar Section CSS */
.topbar .navbar-timer {
  font-size: 16px;
  line-height: 1;
}

.topbar .nav-link {
  cursor: pointer;
}

.topbar .nav-link:hover {
  color: var(--theme-blue1) !important;
}

.topbar .navbar-timer i,
.topbar .nav-link i {
  font-size: 20px;
}

.topbar .nav-link .menu-title {
  font-size: 14px;
  line-height: 1;
}

.topbar .nav-link.active,
.topbar .nav-link.show {
  color: var(--theme-blue1) !important;
}

.topbar .leave-btn i {
  transform: rotate(135deg);
  display: inline-block;
  top: 2px;
  position: relative;
}

.caret-indicator:before {
  content: '';
  display: block;
  height: 15px;
  width: 15px;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: -6px;
  left: 30px;
  transform: rotate(45deg);
  z-index: -111;
}

.dropdown-menu-end.caret-indicator:before {
  left: auto;
  right: 12px;
}


/* Start: Sidebar Drawer CSS */
nav.topbar {
  /* height: var(--topbar-height); */
  background-color: var(--dark-theme-bg) !important;
}

.sidebar {
  height: var(--body-height1);
  width: 300px;
  transform: translateX(300px);
  position: fixed;
  z-index: 1;
  top: var(--topbar-height);
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  background-color: var(--dark-theme-bg2);
}

.participants_container .chip-wrapper {
  margin: 0 -1rem;
  padding: 10px 12px;
}

.chip-wrapper .chip-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

/* Start: Main Video Section CSS */
.video_track_container {
  position: relative;
}

.video_track_container:last-child {
  flex: 1;
}

.network-signals {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 111;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.network-signals .signal {
  width: 2px;
}

.mute-unmute {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 111;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
}

/* Start: Lead Details Section CSS */
.lead-label {
  color: var(--theme-blue1);
  font-size: 14px;
  font-weight: 600;
}

.lead-description {
  color: var(--text-secondary);
  overflow-wrap: anywhere;
}

.lead_details_container .progress-bar {
  align-items: center;
  width: 100% !important;
}

.qna-wrapper .question:first-letter,
.qna-wrapper .answer:first-letter {
  text-transform: capitalize;
}

/* Issue Listing Page CSS */
/* body:has(.sidebar-wrapper) .top-header,
.main-content {
  width: calc(100% - 55px);
  margin-left: 55px;
} */

/* .main-content {
  width: 100%;
  padding-left: 55px;

} */

.main-content {
  width: 100%;
  padding-left: 70px;
}

.issues-listing {
  background-color: var(--dark-theme-bg);
  color: var(--text-secondary);
}

.issues-listing .list-group-item {
  background-color: var(--dark-theme-bg);
  color: var(--text-secondary);
}

.issues-listing .item-description::first-letter {
  text-transform: uppercase;
}



/* Client Progress Staus (Progress bar Status) */
.progress-bar .progress-bar-circ-container svg {
  transform: rotate(-90deg);
}

.progress-bar .progress-bar-percent {
  z-index: 1;
}

.green {
  background-color: green !important;
}

.yellow {
  background-color: yellow;
}

/* CAM BDM Profile Page CSS */
.cam-bdm-attend-list .attendance-wrapper {
  max-height: 50vh;
  overflow: auto;
}

.cam-bdm-attend-list .attendance-item {
  padding: 10px 15px;
  margin-bottom: 8px;
  background-color: #3a3a3a;
  border: 1px solid #464646;
}

.cam-bdm-attend-list .attendance-item i,
.call-history-section .title-value i {
  color: yellow;
  opacity: 0.7;
}

.cam-bdm-attend-list .attendance-item.selected {
  color: #fff;
  background: linear-gradient(168.68deg, #233d94 1.12%, #00a4ff 100%);
  border-color: var(--text-secondary);
}

.cam-bdm-attend-list .attendance-item .title-label,
.call-history-section .title-label {
  color: #fff;
  opacity: 0.7;
}

/* Tab details section css */
.tab-details-section .nav-tabs button.active {
  background: var(--bs-blue);
}

.tab-details-section .tab-content {
  max-height: 62vh;
  overflow: auto;
}

.call-history-section .list-item-wrapper {
  background-color: var(--dark-theme-bg);
}

.call-history-section .title-label {
  width: 35%;
}

/* Start: Meeting Details Page CSS */
.meeting-details-page .info-card {
  color: #8c8c8c;
  font-size: 0.875rem;
  background-color: var(--theme-color3);
}

.meeting-details-page .info-card:hover {
  background-color: #3a3a3a;
}

.meeting-details-page .info-card-details {
  max-height: 65vh;
}

.meeting-details-page .tab-sec-cover {
  background-color: var(--dark-theme-bg) !important;
}

.meeting-details-page .nav li {
  width: calc(100% / 3);
}

.meeting-details-page .nav button {
  font-size: 0.875rem;
  color: #fff;
  background-color: #49494a;
  border: 0.5px solid #000;
  border-radius: 0 !important;
  padding: 10px 5px;
}

.meeting-details-page .nav button.active {
  color: #000;
  background-color: #c4c4c4;
}

.meeting-details-page .user-details {
  padding: 8px 35px 8px 12px;
  background-color: #e9e9e9;
}

.meeting-details-page .user-details:hover,
.meeting-details-page .user-details.selected {
  background-color: #c4c4c4;
  position: relative;
}

.meeting-details-page .user-details.selected:after {
  content: '\F285';
  font-family: 'bootstrap-icons';
  font-size: 30px;
  position: absolute;
  top: 50%;
  right: 1px;
  transform: translateY(-50%);
}

.meeting-details-page .info-card-details .tab-pane {
  max-height: 311px;
  overflow: auto;
}

.meeting-details-page .meeting-pointer-sec {
  background-color: #494949;
}

/* For compose media page */
.compose-media-page .meeting-details-page .comment-part:after {
  content: '';
  display: block;
  border-top: 10px solid var(--custom-bg);
  border-right: 10px solid var(--custom-bg);
  border-bottom: 10px solid transparent;
  transform: rotate(-90deg);
  position: absolute;
  top: -5px;
  right: -5px;
}

.compose-media-page .info-card-details .label,
.compose-media-page .date-time-state {
  font-size: 12px;
}

.meeting-anaylisis-pop .tabs-wrapper .nav {
  overflow-x: auto;
  overflow-y: hidden;
  background-color: transparent;
  border-color: transparent;
}

.meeting-anaylisis-pop .tab-content {
  color: var(--custom-bg);
}

.meeting-anaylisis-pop .tabs-wrapper .nav button {
  color: var(--custom-bg);
  border: 0;
  border-bottom: 5px solid transparent;
  padding: .625rem 0.575rem;
  white-space: nowrap;
}

.meeting-anaylisis-pop .tabs-wrapper .nav button.active {
  color: var(--bs-black);
  font-weight: 600;
  border-bottom-color: var(--bs-blue);
}

@media (max-width: 767.98px) {
  .compose-media-page .info-card-details .btn-sec button {
    font-size: .875rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
  }
}

@media (max-width: 991.98px) {
  .compose-media-page .info-card-details .img-wrapper img {
    max-height: 150px !important;
  }
}

@media (min-width: 991.98px) {
  .compose-media-page .info-card-details .img-wrapper {
    bottom: -1rem;
  }
}

/* End: Meeting Details Page CSS */
.item-description p img {
  max-width: 100%;
}

/* Start: Lead Reports Page CSS */
.lead-reports-page table thead {
  display: contents !important;
}

.lead-reports-page table thead tr {
  height: 50px;
}

.lead-reports-page table th,
.lead-reports-page table td {
  padding: 0.75rem 1rem;
}

#wonChart image {
  border-radius: 50%;
}

/* Start: React Datepicker npm style customization (Globle) */
.calendarWrapper input {
  background-image: url('./resources/images/calendar-icon.png');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: right 13px center;
  padding-right: 40px;
}

.react-datepicker-wrapper .react-datepicker__input-container {
  display: flex;
  align-items: center;
}

.react-datepicker-wrapper .react-datepicker__calendar-icon {
  box-sizing: initial;
  fill: var(--bs-gray-700)
}

.wonDatePicker {
  border-radius: 5px;
  text-align: center;
  margin-bottom: 15px;
  border: 0;
  min-height: 40px;
}

/* End: React Datepicker npm style customization */

/* client & Cam, Bdm details  */
.client-detail-wrapper .client-list-section,
.client-detail-wrapper .client-info-details {
  max-height: 85vh;
  overflow: auto;
}

.client-list-section .search-bg-color {
  background-color: #282826;
}

.searchfield-wrapper input[type="search"]:focus~img,
.searchfield-wrapper input[type="search"]:valid~img {
  display: none
}

.searchfield-wrapper input[type="search"]:placeholder-shown+img {
  display: block !important;
}

.client-list-section .searchfield-wrapper img {
  z-index: 1;
}

.client-detail-wrapper .client-card {
  background-color: #373737;
}

.client-detail-wrapper .client-name {
  line-height: normal;
  letter-spacing: 1px;
}

.client-detail-wrapper .client-card.selected {
  background: linear-gradient(168.68deg, rgb(0, 82, 201) 1.12%, rgb(10, 145, 219));
  position: relative;
}

.client-detail-wrapper .client-card.selected:after {
  content: '\F285';
  color: var(--bs-white);
  font-family: 'bootstrap-icons';
  font-size: 30px;
  position: absolute;
  top: 50%;
  right: 1px;
  transform: translateY(-50%);
}

.client-detail-wrapper .client-info-details {
  background-color: #282826;
}

.client-info-details .attn-details-wrapper {
  background-color: #3a3a3a;
}

.cam-bdm-attn-details .view-more-box {
  background-color: #4d4b4c;
}

.attn-details-wrapper .attn-name .badge {
  font-size: 12px;
  min-width: 90px;
}

/* 
* preloader
*/
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

/*
* end preloder
*/

.react-datepicker-popper {
  z-index: 2 !important;
}

/*
* joing preloader
*/
.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

#view-button-area-join-now {
  display: none;
}

.loader-joining {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  margin: 0 auto;
}

.loader-joining:before,
.loader-joining:after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}

.loader-joining:before {
  animation: ball1 1s infinite;
  background-color: #cb2025;
  box-shadow: 30px 0 0 #f8b334;
  margin-bottom: 10px;
}

.loader-joining:after {
  animation: ball2 1s infinite;
  background-color: #00a096;
  box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }

  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }

  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}

.loader-force-logout {
  position: relative;
  width: 100px;
  height: 16px;
}

.loader-force-logout:before,
.loader-force-logout:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 32px 0 #fff;
  left: 0;
  top: 0;
  animation: ballMoveX 2s linear infinite;
}

.loader-force-logout:after {
  box-shadow: none;
  transform: translateX(64px) scale(1);
  z-index: 2;
  animation: none;
  animation: trfLoader 2s linear infinite;
}

@keyframes trfLoader {

  0%,
  5% {
    transform: translateX(64px) scale(1);
    background: #FFF;
  }

  10% {
    transform: translateX(64px) scale(1);
    background: #ff3d00;
  }

  40% {
    transform: translateX(32px) scale(1.5);
    background: #ff3d00;
  }

  90%,
  95% {
    transform: translateX(0px) scale(1);
    background: #ff3d00;
  }

  100% {
    transform: translateX(0px) scale(1);
    background: #FFF;
  }
}

@keyframes ballMoveX {

  0%,
  10% {
    transform: translateX(0)
  }

  90%,
  100% {
    transform: translateX(32px)
  }
}

.chatboat-page .react-datepicker-wrapper {
  width: 100%;
}


Add this css in at the end

/* Start: Marketing Data Page */
.marketing_details .tabs_card ul {
  overflow-x: auto;
  overflow-y: hidden;
}

.marketing_details .tabs_card .nav-link {
  background-color: transparent;
  border: 0;
  border-bottom: 5px solid transparent;
  color: var(--text-secondary);
  white-space: nowrap;
}

.marketing_details .tabs_card .nav-link.active {
  color: var(--bs-white);
  font-weight: 500;
  border-bottom-color: var(--bs-blue);
}

.marketing_details .tabs_card .head-label {
  color: var(--text-secondary);
}

.marketing_details .queries_replies_wrapper:not(:last-child) {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--custom-bg);
}

@media (max-width: 991.98px) {
  .marketing_details .tabs_card .nav-link {
    font-size: .875rem;
  }
}

/* End: Marketing Data Page */

/* CRM feedback css */
.ques_ans_wrapper,
.ans_detail {
  color: #fff !important;
}

.ques_ans_wrapper,
.ques_detail {
  color: #f1c40f !important;
}

/* end crm css */

/* Start: New Recruitment Page CSS */
.recruitment-page form input,
.recruitment-page form select,
.recruitment-page form textarea {
  background-color: var(--custom-bg);
  color: var(--bs-white) !important;
}

.recruitment-page input:focus,
.recruitment-page select:focus,
.recruitment-page textarea:focus {
  background-color: var(--custom-bg);
}

/* Input placeholder */
.recruitment-page form input::placeholder {
  color: var(--text-secondary)
}

.recruitment-page form input::-webkit-input-placeholder {
  color: var(--text-secondary)
}

.recruitment-page form input::-moz-placeholder {
  color: var(--text-secondary)
}

.recruitment-page form input:-ms-input-placeholder {
  color: var(--text-secondary)
}

/* Select placeholder */

.recruitment-page form .custom-select {
  position: relative;
}

.recruitment-page form .custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.recruitment-page form .custom-select:before {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--text-secondary);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
  content: "";
  z-index: 1;
}

.recruitment-page form select:required:invalid {
  color: var(--text-secondary) !important;
}

/* Date input placeholder */
.recruitment-page form input[type=date]:invalid::-webkit-datetime-edit {
  color: var(--text-secondary)
}

.recruitment-page form input[type=date]:invalid::-webkit-datetime-edit {
  color: var(--text-secondary) !important;
}

.recruitment-page form input[type=date]:invalid::-webkit-datetime-edit {
  color: var(--text-secondary) !important;
}

.recruitment-page form ::-webkit-calendar-picker-indicator {
  filter: invert(80%);
}


/* Textarea placeholder */
.recruitment-page form textarea::placeholder {
  color: var(--text-secondary);
}

.recruitment-page .technical-test-sec input[type=checkbox]:checked {
  background-color: #9d9d9d;
}

.recruitment-page .custom-quill-editor .ql-toolbar:before {
  display: none;
}

.recruitment-page .custom-quill-editor .ql-container {
  min-height: 135px;
}

/* End: New Recruitment Page CSS */

/* Start: Recruitment Listing Page CSS */
.recruitment-list-page .job-card-wrapper,
.recruitment-list-page .job-details-wrapper {
  height: calc(var(--body-height1) - 122px);
  overflow: auto;
}

.recruitment-list-page .filter-option {
  min-width: 140px;
  flex: 1;
}

.recruitment-list-page .job-card-wrapper .job-card.selected {
  background-color: var(--custom-bg);
}

.recruitment-list-page .job-card-wrapper .job-card:not(.selected):hover {
  background-color: var(--theme-color3);
}

.recruitment-list-page .job-card-wrapper .job-card:first-child {
  border-top-left-radius: 0.5rem;
}

.recruitment-list-page .job-card-wrapper .job-card:last-child {
  border-bottom-left-radius: 0.5rem;
}

.recruitment-list-page .job-card-wrapper .job-card:not(:last-child) {
  border-bottom: 1px solid var(--bs-secondary);
}

.recruitment-list-page .job-details-wrapper .add-dot {
  position: relative;
  padding-right: 30px;
}

.recruitment-list-page .job-details-wrapper .add-dot:before {
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: var(--bs-light);
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.recruitment-list-page .job-details-wrapper .hr-profiles img {
  height: 30px;
  width: 30px;
  background-color: var(--bs-primary);
  z-index: 1;
}

.recruitment-list-page .job-details-wrapper .hr-profiles img:not(:first-child) {
  margin-left: -15px;
}

.recruitment-list-page .pagination li a:not(.active) {
  background-color: var(--custom-bg);
  border-color: var(--bs-gray-600);
  box-shadow: none;
  color: var(--text-secondary);
}

/* End: Recruitment Listing Page CSS */

.animated-background-requisition {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #736666 8%, #bbbbbb 18%, #211f1f 34%);
  background-size: 800px 104px;
  height: 100%;
  position: relative;
}

.disabled-overlay {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  pointer-events: none;
  /* Ignore pointer events */
}

/* Date range resuit*/
/* @import "rsuite/dist/rsuite.css"; */
@import 'rsuite/dist/rsuite-no-reset.min.css';

.rs-anim-fade {
  left: 742.828px !important;
}

/*Custom loader*/
/* HTML: <div class="profile-loader"></div> */
/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.profile-loader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 20px;
  color :#0000;
  overflow: hidden;
  animation: l9 5s infinite cubic-bezier(0.3,1,0,1);
}
.profile-loader:before {
  content:"wait..."
}
@keyframes l9 {
  0%  {text-shadow: 0    0 #000, 11ch 0 #8A9B0F, 22ch 0 #C02942, 33ch 0 #00A0B0,44ch 0 #000}
  25% {text-shadow:-11ch 0 #000,  0ch 0 #8A9B0F, 11ch 0 #C02942, 22ch 0 #00A0B0,33ch 0 #000}
  50% {text-shadow:-22ch 0 #000,-11ch 0 #8A9B0F,  0ch 0 #C02942, 11ch 0 #00A0B0,22ch 0 #000}
  75% {text-shadow:-33ch 0 #000,-22ch 0 #8A9B0F,-11ch 0 #C02942,  0ch 0 #00A0B0,11ch 0 #000}
  100%{text-shadow:-44ch 0 #000,-33ch 0 #8A9B0F,-22ch 0 #C02942,-11ch 0 #00A0B0, 0ch 0 #000}
}

/* .gmail_quote {
  display: none; 
} */
.expand-button {
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.recruitment-editor .ql-toolbar.ql-snow{
  background-color: #8d8d8d !important;
}

.recruitment-editor .ql-toolbar.ql-snow + .ql-container.ql-snow{
  color: #777777;
}

/* styles.css */
.slider {
  width: 100%;
}

.slider-track {
  background: #ddd;
}

.slider-thumb {
  background: #007bff;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}


/* HTML: <div class="loader-crm"></div> */
.loader-crm {
  height: 4px;
  width: 130px;
  --c:no-repeat linear-gradient(#6100ee 0 0);
  background: var(--c),var(--c),#d7b8fc;
  background-size: 60% 100%;
  animation: l16 3s infinite;
}
@keyframes l16 {
  0%   {background-position:-150% 0,-150% 0}
  66%  {background-position: 250% 0,-150% 0}
  100% {background-position: 250% 0, 250% 0}
}

.passcode-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  text-align: center; /* Center the text inside the div */
}

.passcode-container h3 {
  margin-bottom: 20px;
}

.passcode-container input {
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  width: 200px;
  text-align: center; /* Center text in the input */
}

.passcode-container button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.custom-headset {
  color: chartreuse !important;
}

.custom-incentive {
  color: rgb(214, 70, 243) !important;
}

.custom-credit{
  color: rgb(82, 201, 248) !important;
}

.custom-wallet {
  color: #f0e007 !important;
}

.char-counter {
  font-size: 0.9rem;
  color: #555;
  text-align: right;
}
.char-counter.error {
  color: red;
}


